/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
// @import "~@ng-select/ng-select/themes/default.theme.css";

@import './lib/settings';
@import './lib/functions.less';
@import './classes/classes.less';
@import './lib/icons/icon.less';
@import './lib/mixins.less';
@import './pages/home/mobile-nav.less';

@import './pages/home/googlemaps.less';
@import './pages/home/dropdownliste.less';
@import './pages/home/row-3-block.less';
@import './pages/home/kontakt-form.less';
@import './pages/impressum/impressum.less';
@import './pages/datenschutz/datenschutz.less';




html{
	min-height: 100%;
	width:100%;
	height:100%;
	margin:0;
	padding:0;
    overflow:hidden;
    overflow-y: auto;
} 

body{
    // touch-action: manipulation;
	min-height: 100%;
	width:100%;
	height:100%;
	display:block;
	position: relative;
	z-index: 0;
	padding: 0;
	margin: 0;
	min-width: 100%;
	font-size: @f_mainSize;
    background-color:@c_5th;
    overflow: hidden;
    overflow-y: auto;

    .wrapper-body{

        .transform2d(@translateX: -50%);

		width: @s_1stMainWrapper;
		margin-left: 50%;

        @media screen and(max-width: @s_1stMainWrapper){
            width: calc(@s_3rdMainWrapper - 40px);
        }
        @media screen and(max-width: @s_3rdMainWrapper){
            width: calc(100% - (@p_main * 2));
        }
	}
	.wrapper-content{

		display:block;
        margin-left: 50%;
		padding: @p_main;
		width: calc(@s_1stMainWrapper - 40px);
		height: calc(~"100% - 111px");

        .transform2d(@translateX: -50%);

        h1.alex{
            margin-top: (@p_main * 3);
        }

        @media screen and(max-width: @s_1stMainWrapper){
            width: calc(@s_3rdMainWrapper - 40px);
        }
        @media screen and(max-width: @s_3rdMainWrapper){
            width: calc(100% - (@p_main * 2));
        }
	}
    .wrapper-navigation{
        
        display: block;
        width:100%;
        height: @h_navigation;
        background-color: @c_1st;
        position: fixed;
        top: 0;
        z-index:10000;

        // .gradient1;
    }
}

::selection{
	background-color: @c_1st;
	color: @c_5th;
}

*{
	box-sizing:( @p_main * 0.5 );
}

.btn{

	padding: 15px 30px 13px 30px;
	border:none;
	outline:none;
	border-radius: 10px;
	font-size: @f_mainSize;
	cursor: pointer;

	.f_1stFontBold;
}

.btn-primary{
	
    .f_1stFont;

    color: @c_1st;
    background-color: @c_5th;
    padding: (@p_main / 4) (@p_main * 2);
    font-size: @f_mainSize;
    border-radius: @p_main;
    cursor: pointer;
	
	.standardAnimation(@_time: 150ms);

	&:hover{
		
        color: @c_2nd;
        background-color: @c_17th;
        
        .standardAnimation(@_time: 150ms);
		
	}
}

.btn-secondary{

    .f_1stFont;
	
    display: inline-block;
    color: @c_5th;
    background-color: @c_1st;
    padding: (@p_main / 4) @p_main;
    font-size: (@f_mainSize * 0.65);
    border-radius: @p_main;
    cursor: pointer;
    outline: none;
    border: none;
	
	.standardAnimation(@_time: 150ms);

	&:hover{
		
        color: @c_7th;
        background-color: @c_2nd;
        
        .standardAnimation(@_time: 150ms);
		
	}
}

input[type="text"], input[type="password"], input[type="email"], input[type="number"]{

	padding: 17px 20px 13px 40px;
	border:none;
	outline: none;
	background-color: @c_12th;
	border-radius: 10px;
	font-size: @f_mainSize;
    color: @c_17th;

	.f_1stFont;

	&::placeholder{
		color: @c_1st;
	}
}
input[type="text"].input-border{
	border: 1px solid @c_1st;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-element-item{

	// padding: 17px 20px 13px 40px;
	padding: 7px 0 3px 0;
	border:none;
	outline: none;
	background-color: transparent;
	color: @c_1st;
	border-radius: 0;
	font-size: @f_mainSize;
	border-bottom: 1px solid @c_1st;
	margin-bottom: @p_main / 2;

	.f_1stFontBold;

	&::placeholder{
		color: @c_1st;
	}
}

.gu-mirror {
	position: fixed !important;
	margin: 0 !important;
	z-index: 9999 !important;
	opacity: 0.8;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
	filter: alpha(opacity=80);
	pointer-events: none;
  }
  /* high-performance display:none; helper */
  .gu-hide {
	left: -9999px !important;
  }
  /* added to mirrorContainer (default = body) while dragging */
  .gu-unselectable {
	-webkit-user-select: none !important;
	-moz-user-select: none !important;
	-ms-user-select: none !important;
	user-select: none !important;
  }
  /* added to the source element while its mirror is dragged */
  .gu-transit {
	opacity: 0.2;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
	filter: alpha(opacity=20);
  }

  .box-search {
    
    margin-right: @p_main ;
    position: relative;
    
    input {
        .f_1stFont;
        width: 240px;
        font-size: @f_mainSize;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 20px;
        border: 1px solid @c_1st;
        background-color: @c_14th;
        color: @c_1st;
        border-radius: 20px;
        outline: none;
        &:active,
        &:focus {
            background-color: @c_11th;
            border: 1px solid @c_7th;
            color: @c_17th;
            outline: none!important;
            &::placeholder {
                color: @c_17th!important;
            }
        }
    }
    input::placeholder {
        color: @c_1st;
    }
    .icon {
        display: block;
        color: @c_9th;
        position: absolute;
        top: 8px;
        right: 15px;
    }
    input:focus~.icon {
        color: @c_8th!important;
    }
}

.input-field{

    margin-bottom: @p_main / 2;
    position: relative;

    label{

        .f_1stFont;

        display:block;
        font-size: (@f_mainSize * 1.125);
        margin-bottom: (@p_main / 2);
        color: @c_13th;
    }
    
    input {
        
        .f_1stFont;

        font-size: @f_mainSize * 1.125;
        width:calc(100% - ((@p_main * 2) + 2px));
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: @p_main;
        padding-right: @p_main;
        border: 1px solid @c_7th;
        color: @c_13th;
        border-radius: @r_borderRadius;
        background-color: @c_5th;
        outline: none;
        &:active,
        &:focus {
            background-color: @c_15th;
            border: 1px solid @c_1st;
            color: @c_1st;
            outline: none!important;
            &::placeholder {
                color: @c_8th!important;
            }
        }
        &.border-red, &.not-valid{
            border-color: @c_petrolRed!important;
            background-color: @c_3rd!important;
            color: @c_5th!important;
        }
        &.success{
            border: 1px solid @c_4th;
            
            color: @c_4th;
        }
        &.failed{
            border: 1px solid @c_3rd;
            color: @c_3rd;   
        }
    }
    input::placeholder {
        color: @c_7th;
    }
    .icon {
        display: block;
        color: @c_9th;
        position: absolute;
        top: 8px;
        right: 15px;
    }
    input:focus~.icon {
        color: @c_8th!important;
    }
    p.error-msg{
        .f_1stFontBold;
        font-size: @f_mainSize * 0.75;
        color: @c_petrolRed;
        margin-top: @p_main / 2;
        margin-bottom: @p_main / 2;
        padding:0;
    }
    &.buchbar{
        min-width:145px!important;
        max-width:145px!important;
        width:145px!important;
    }
}

.button-right{

    .displayFlex(row);
    .justifyContent(space-between);

    .right{
        .displayFlex(row);
        .justifyContent(flex-end);
    }
}

.input-textarea{

    width: 100%;
    margin-right: @p_main ;
    margin-bottom: @p_main / 2;
    position: relative;

    label{
        .f_1stFont;

        display:block;
        font-size: (@f_mainSize * 1.125);
        margin-bottom: (@p_main / 2);
        color: @c_13th;
    }

    textarea {

        .f_1stFontBold;

        font-size: @f_mainSize * 1.125;
        width:calc(100% - 32px);
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 20px;
        border: 1px solid @c_7th;
        color: @c_8th;
        border-radius: @r_borderRadius;
        outline: none;
        &:active,
        &:focus {
            background-color: @c_17th;
            border: 1px solid @c_1st;
            color: @c_8th;
            outline: none!important;
            &::placeholder {
                color: @c_8th!important;
            }
        }
        &.border-red{
            border-color: @c_petrolRed!important;
        }
    }
    textarea::placeholder {
        color: @c_9th;
    }
}

.light{
    input[type="text"], input[type="password"], input[type="email"], input[type="number"], textarea{
        
        background-color: @c_5th;

        &:focus {
            background-color: @c_15th;
            border: 1px solid @c_1st;
            color: @c_12th;
            outline: none!important;
            &::placeholder {
                color: @c_8th!important;
            }
        }
    }
}

.dark{
    input[type="text"], input[type="password"], input[type="email"], input[type="number"], textarea{
       
        background-color: @c_11th;

        &:focus {
            background-color: @c_12th;
            border: 1px solid @c_1st;
            outline: none!important;
            &::placeholder {
                color: @c_8th!important;
            }
        }
    }
}

.label{
	.f_1stFontBold;
	color: @c_2nd;
	font-size: @f_mainSize * 0.7;
}


.select-field{

    select{

        .f_1stFontBold;

        font-size: @f_mainSize * 0.75;
        width: 100%;
        border-radius: 5px;
        padding-left: 10px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 10px;
        padding-right: 20px;
        border: 1px solid @c_7th;
        background-color: @c_12th;
        color: @c_17th;
        outline: none;
        // -moz-padding-start: calc(10px - 3px);
        // -moz-appearance: none;
        // -webkit-appearance: none;
        // appearance: none;

        &:focus{
            border: 1px solid @c_1st; 
        }
        
        option{
            .f_1stFontBold;

            font-size: @f_mainSize * 0.75;
            border: 1px solid @c_8th;
        }
    }
    select:focus::-ms-value {
        background-color: transparent;
        border: 1px solid @c_1st;
    }

    &.no-margin-top{
        margin-top:@p_main / 2!important;
    }

    &.button-right{

        .displayFlex(row);
        .justifyContent(space-between);

        .right{
            .displayFlex(row);
            .justifyContent(flex-end);
        }
    }
}

.toggle-field{

    p{
        .f_1stFontBold;
        color: @c_9th!important;
        font-size: @f_mainSize;
        display:inline-block;
        margin-left: calc(~"75px + 20px");
        margin-top: 2px;
        opacity: 1;
    }

    .toggle-control {
        display: block;
        position: relative;
        padding-left: 75px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        user-select: none;
      
        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
      
        input:checked ~ .control {
          background-color: @c_nanoGreen!important;
          
          &:after {
            left: 75px - (30px - ( 5px * 2 )) - 5px;
          }
        }
      
        .control {
          position: absolute;
          top: 0;
          left: 0;
          height: 30px;
          width: 75px;
          border-radius: 23px;
          background-color: @c_4th;
          transition: background-color 150ms ease-in;
      
          &:after {
            content: "";
            position: absolute;
            left: 5px;
            top: 5px;
            width: 20px ;
            height: 20px;
            border-radius: 50%;
            background: @c_5th;
            transition: left 150ms ease-in;
          }
        }
    }
}

.code {
    background-color: rgba(27,31,35,.05);
    border-radius: 3px;
    font-size: 85%;
    margin: 0;
    padding: .2em .4em;
  }



  .cdk-drag-placeholder {

    .f_1stFontBold;

    opacity: 1;
    color: @c_8th!important;
    display:inline-block;
    width:100%;
    border: none!important;
    margin-bottom: 0;
    padding: 0;
    background-color: @c_8th!important;
    // background-color: blue!important;

  }

  .cdk-drag-preview {

    .f_1stFontBold;
    color: @c_8th!important;
    box-sizing: border-box;
    list-style:none;
    opacity: 1;
    display:inline-block;
    width:calc(~"100% - 40px");
    border: 1px solid @c_7th;
    padding-left: @p_main;
    margin-top:0;
    // background-color: @c_11th!important;
    background-color: transparent!important;

    // box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    //             0 8px 10px 1px rgba(0, 0, 0, 0.14),
    //             0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  
  

ul{
    list-style:none;
    margin:0;
    padding:0;
}

.tooltip-container {
    
    .f_1stFont;
    .fc-5;

    text-align: center;
    z-index: 100;
    position: fixed;
    padding: (@p_main / 4) @p_main;
    font-size: @f_mainSize * 0.85;
    line-height: initial;
    width: auto;
    background: @c_9th;
    box-sizing: border-box;
    border-radius: (@p_main / 4);
    opacity: 0;
    transform: translate(-50%, -30%);
    animation: tooltip-slide 0.18s ease-out 0.5s;
    animation-fill-mode: forwards;
    pointer-events: none;
}

@keyframes tooltip-slide {
  0% {
    opacity: 0;
    transform: translate(-50%, -30%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.pp-checkbox{

    .displayFlex(row);
    .justifyContent(flex-start);
    .alignItems(stretch);

    cursor:pointer;

    .icon-checkbox{

        display: inline-block;
        margin-right: (@p_main / 4);
        margin-top: 4px;

        .standardAnimation(@_time: 150ms);

        .pp-okay {
            width:9px;
            height: 9px;
            border-radius: 50%;
            padding: 2px;
            border: 2px solid @c_1st;

            i{
                background-color: @c_1st;
            }
        }
    }
    &.not-selected{
        .icon-checkbox{
            .pp-okay{

                border-color: @c_8th;

                i{
                    background-color: transparent;
                }
            }
        }
    }
}


.tab-liste{
    
    .displayFlex(row);
    .justifyContent(flex-start);

    width: 100%;

    .tab{
        display: inline-block;
        padding: (@p_main / 4) (@p_main / 2);
        background-color: @c_10th;
        cursor:pointer;

        .standardAnimation(@_time: 150ms);

        &.selected{
            background-color: @c_1st;
        }

        &:hover{
            background-color: @c_7th;
        }

        &:first-child{
            border-top-left-radius: @r_borderRadius;
        }
        &:last-child{
            border-top-right-radius: @r_borderRadius;
        }
    }
}

ul.list-with-header{

    display: inline-block;
    padding: (@p_main / 2);
    width: calc(100% - @p_main);
    height: calc(100% - @p_main);
    overflow: hidden;
    overflow-y: auto;
    background-color: @c_12th;
    border-radius: (@r_borderRadius / 2);

    .scrollbar1;

    li{

        .displayFlex(row);
        .justifyContent(flex-start);

        width: calc(100% - 2px);
        padding: (@p_main / 4) 0;
        background-color: @c_24th;

        &.element{

            border: 1px solid transparent;
            cursor: pointer;

            .standardAnimation(@_time: 150ms);

            &:hover{
                border:1px solid @c_1st;
            }
        }

        &.selected{
            border:1px solid @c_1st;
        }

        div.element{
            padding: 0 (@p_main / 4);
        }

        .icons{

            .displayFlex(row);
            .justifyContent(flex-end);

            pp-button-edit{
                margin-right: (@p_main / 4);
            }
        }

        &.odd{
            background-color: @c_14th;
        }
        &.list-header{

            border-bottom: 1px solid @c_9th;
            background-color: transparent;
            padding:0;
            width: 100%;
            
            div{
                background-color: @c_9th;
                padding: (@p_main / 4);
            }
            
            .first{
                border-top-left-radius: (@r_borderRadius / 2);
            }
            .last{
                border-top-right-radius: (@r_borderRadius / 2);
            }
        }
    }
}

.row{

    .displayFlex(row);
    .justifyContent(flex-start);

    width: 100%;
    margin-bottom: (@p_main / 4);

    .standardAnimation(@_time: 150ms);

    .outline{

        border: 1px solid @c_9th;
        border-radius: (@r_borderRadius / 2);
        background-color: @c_12th;
        
        pp-p{
            display: inline-block;
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: @p_main;
            padding-right: @p_main;
            width: calc(100% - ((@p_main * 2) + 2px))!important;
        }
    }

    .click{

        cursor: pointer;

        &:hover{
            border: 1px solid @c_1st;
        }
    }
}

.box-tab-liste-klein-global{

    display: inline-block;
    width: 100%;
    padding-top: (@p_main / 2);
    padding-bottom: (@p_main / 2);
    border-bottom: 1px solid @c_10th;

    .box-btn{

        display: inline-block;
        cursor:pointer;
        width: calc(100% - @p_main);
        padding: (@p_main / 4) (@p_main / 2);
        border-bottom-left-radius: (@r_borderRadius * 1.5);
        border-top-left-radius: (@r_borderRadius * 1.5);

        .standardAnimation(@_time: 150ms);

        &:hover{
            background-color: @c_10th; 
        }

        &.active{
            background-color: @c_1st;

            &:hover{
                background-color: @c_7th; 
            }
        }

    }
}

.margin-top{
    margin-top: @p_main;
}
.margin-bottom{
    margin-bottom: @p_main;
}
.right{
    .displayFlex(row);
    .justifyContent(flex-end)!important;
}

.wrapper-keinkunde{

    display: inline-block;
    width: 100%;
    min-height: calc(100% - (@h_navigation + @h_footer));
    margin-top: @h_navigation;

    h1{
        .f_1stFontBold;

        text-align:center;
        margin-top: (@p_main * 4);
    }
}


.margin-nav{
    margin-top: @h_navigation;
}

a{
    text-decoration: none;
    color: @c_1st;

    .standardAnimation(150ms);

    &:hover{
        color: @c_2nd;
    }
}

.logo{

    display: inline-block;

    img{
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
}

.copyright{

    .displayFlex(row);
    .justifyContent(center);

    p{
        .f_1stFont;

        font-size: 10px;
        text-align: center;
        opacity: 0.45;

        span{

            display: inline-block;
            font-size: 120%;
            padding-left: (@p_main / 4);
            padding-right: (@p_main / 4);

            &.heart{
                font-size: 150%;
            }
        }

        a{
            .f_1stFont;
            font-size: 10px;
        }
    }
    @media screen and(max-width: @s_4thMainWrapper){
        p{
            span{

                font-size: 150%;

                &.heart{
                    font-size: 110%;
                }
            }
        }
    }
}

#navigation{
    @media screen and(max-width: @s_3rdMainWrapper) {
        display: none;
    }
}

#mobil-navigation{

    display: none;

    @media screen and(max-width: @s_3rdMainWrapper) {
        display: inline-block;
    }
}

html, body { 
    height: 100%;
    // scrollbar-gutter: stable;
 }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@import './classes/font-classes.less';