@import '../../lib/settings.less';
@import '../../classes/classes.less';

#mobil-navigation{

    position: fixed;
    width: 100%;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;

    .wrapper-mobile-nav{

        .displayFlex(row);
        .justifyContent(center);

        width: 100%;

        .box-logo{

            display: inline-block;
            height: calc(75% - @p_main);
            padding-top: (@p_main / 2);

            span.background{
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
            }

            .logo{
    
                display: inline-block;
                height: 100%;
                cursor: pointer;
    
                img{
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        .box-icon{

            display: inline-block;
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;
            z-index: 10000;

            .icon{

                display: inline-block;
                margin: (@p_main / 2);
                width: calc(100% - @p_main);
                height: calc(100% - @p_main);

                .pp-icon{

                    .transform2d(@translateX: -50%; @translateY: -50%);

                    display: inline-block;
                    position: relative;
                    margin-top: 50%;
                    margin-left: 50%;
                    width: 40px;
                    height: 2px;
                    background-color: var(--iconColor);

                    &:before, &:after{
                        content: " ";
                        display: inline-block;
                        position:absolute;
                        width: 40px;
                        height: 2px;
                        background-color: var(--iconColor);
                    }

                    &:before{
                        top: -7px;
                    }

                    &:after{
                        top: 7px;
                    }
                }
            }
        }
        .box-links{

            .scrollbar1;
            .transform2d(@translateY: -100%);
            .standardAnimation(@_time: 250ms);

            display: inline-block;
            width: 100%;
            height: auto;
            position: absolute;
            z-index: -2;
            overflow: hidden;
            overflow-y: auto;

            &.open{
                .transform2d(@translateY: 0);
            }

            ul{

                display: inline-block;
                width: 100%;

                li{
                    display: inline-block;
                    padding: @p_main;
                    width: calc(100% - (@p_main * 2));

                    a{  
                        display: inline-block;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }
}