@import '../../lib/settings.less';
@import '../../classes/classes.less';

.section-kontakt-form{

    display: inline-block;
    width: 100%;
    padding-top: (@p_main * 2);
    padding-bottom: (@p_main * 2);

    .wrapper-content{

        h6{
            .f_1stFontBold;

            color: @c_12th;
            font-size:(@f_mainSize * 2);
            margin-bottom: (@p_main * 2);
        }

        #contact-form{

            display: inline-block;
            width: 100%;

            .box-top{
                
                .displayFlex(row);
                .justifyContent(flex-start);

                width: 100%;

                .box{
                    
                    display: inline-block;
                    width: calc(33% - @p_main);
                    margin-left: (@p_main / 2);
                    margin-right: (@p_main / 2);

                    &:first-child{
                        margin-left: 0;
                        width: calc(33% - (@p_main / 2));
                    }
                    &:last-child{
                        margin-right: 0;
                        width: calc(33% - (@p_main / 2));
                    }
                }
            }

            .box-response{

                display: inline-block;
                width:100%;
                margin-top: @p_main;
                margin-bottom: @p_main;

                p{
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                }
            }

            .box-bottom{

                display: inline-block;
                width:100%;
                margin-top: 0;
                margin-bottom: @p_main;
            }

            .box-button{

                .displayFlex(row);
                .justifyContent(center);

                width: 100%;
                margin-top: (@p_main * 2);

                button{
                    font-size: @f_mainSize;
                    padding: (@p_main / 2) (@p_main * 2);
                }
            }
            @media screen and(max-width: @s_3rdMainWrapper){
                .box-top{
                    
                    .displayFlex(column);
    
                    .box{
                        width: calc(100% - @p_main)!important;
                        margin-left: (@p_main / 2)!important;
                        margin-right: (@p_main / 2)!important;
                        margin-top: @p_main !important;
                    }
                }
                .box-bottom{
                    width: calc(100% - (@p_main * 1.25));
                    margin-left: @p_main;
                    margin-right: @p_main;
                }
            }
        }

        @media screen and(max-width: @s_3rdMainWrapper){
            width: calc(100% - (@p_main * 2));
        }
    }
}