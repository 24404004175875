@import '../../lib/settings.less';
@import '../../lib/animations.less';
@import '../../classes/classes.less';

.wrapper-liste{

    display: inline-block;
    width: 100%;
    padding-top: (@p_main * 2);
    padding-bottom: (@p_main * 5);

    .wrapper-content{
        .headline{

            display: inline-block;
            width: 100%;

            h2{
                .f_2ndFont;
    
                font-size: (@f_mainSize * 2.5);
                text-align: center;
            }
        }
        .box-content-liste{

            display: inline-block;
            width: 730px;
            margin-top: (@p_main * 3);
            margin-left: 50%;

            .transform2d(@translateX: -50%);

            ul{

                display: inline-block;
                width: 100%;

                li{
                    display: inline-block;
                    width: 100%;
                    padding-top: @p_main;
                    border-bottom: 1px solid @c_7th;
                    cursor: pointer;

                    .box-dropdown-list-item{

                        display: inline-block;
                        width: 100%;

                        .box-top{
                
                            .displayFlex(row);
                            .justifyContent(space-between);

                            width: 100%;

                            @w_boxIcon: 25px;
            
                            .box-left{

                                display: inline-block;
                                width: calc(100% - @w_boxIcon);

                                h6{
                                    .f_1stFontBold;
                                    
                                    color: @c_12th; 
                                    font-size: (@p_main * 2);
                                }
                            }
                            .box-right{

                                display: inline-block;
                                width: @w_boxIcon;
                                height: @w_boxIcon;
                                margin-top: 15px;
                               
                                .icon{

                                    display: inline-block;
                                    position:relative;
                                    width: @w_boxIcon;
                                    height: @w_boxIcon;

                                    .standardAnimation(150ms);

                                    &:after{

                                        content: " ";
                                        position:absolute;
                                        display: inline-block;
                                        width: @w_boxIcon;
                                        height: 2px;
                                        background-color:@c_9th;
                                        top: calc((@w_boxIcon / 2) - 1px);
                                        left:0;
                                    }

                                    &:before{

                                        content: " ";
                                        display: inline-block;
                                        width: 2px;
                                        height: @w_boxIcon;
                                        background-color:@c_9th;
                                        position:absolute;
                                        top: 0;
                                        left: 50%;
                                        
                                        .transform2d(@rotate: 0);
                                        .standardAnimation(150ms);
                                    }
                                }
                            }
                        }
                        .box-content{

                            display: inline-block;
                            width: 100%;
                            margin:0;
                            padding: 0;
                            height: 0;
                            overflow:hidden;

                            .standardAnimation(150ms);

                            p{
                                .f_1stFont;

                                line-height: 1.55;
                                margin: 0;
                                color: @c_5th;
                                
                                .standardAnimation(350ms);
                            }
                        }
                        &.open{
                            .box-top{
                                .box-right{
                                    .icon{
                                        &:before{
                                            .transform2d( @rotate: -90deg);
                                        }
                                    }
                                }
                            }
                            .box-content{

                                height: auto!important;

                                p{
                                    color: @c_9th;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media screen and(max-width: @s_3rdMainWrapper){
            .box-content-liste{
                width: 100%;
            }
        }
    }
}