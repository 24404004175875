@import '../../lib/settings.less';
@import '../../classes/classes.less';

.section-datenschutz{

    display: inline-block;
    width: 100%;
    margin-bottom: @h_navigation;
    min-height: calc(100% - ((@h_navigation * 2) + @h_footer));

    p{
        .f_1stFont;

        line-height: 1.75;
        margin: 0;
    }

    h4{
        .f_1stFont;
        
        font-size: (@f_mainSize * 1.5);
        color: @c_1st;
    }

    h5, h6{
        font-size: (@f_mainSize * 1.75);
    }

    h6{
        .f_1stFontBold;

        font-size: (@f_mainSize * 1.25);
        margin-top: @p_main;
    }

    .person{

        display: inline-block;
        width: calc(100% - (@p_main * 2));
        padding: @p_main;
        background-color: @c_15th;
        margin-top: @p_main;
        margin-bottom: @p_main;
    }
}