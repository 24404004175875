@import '../settings.less';
@import '../animations.less';

.pp-dashboard{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Dashboard-2.svg');
        -webkit-mask-image: url('../../../assets/svg/Dashboard-2.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-vertrag{

    display:block;
    width:100%;
    height:100%;

    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/vertrag.svg');
        -webkit-mask-image: url('../../../assets/svg/vertrag.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-csv{

    display:block;
    width:100%;
    height:100%;

    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/File-csv.svg');
        -webkit-mask-image: url('../../../assets/svg/File-csv.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-excel{

    display:block;
    width:100%;
    height:100%;

    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/File-excel.svg');
        -webkit-mask-image: url('../../../assets/svg/File-excel.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}


.pp-user{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/User.svg');
        -webkit-mask-image: url('../../../assets/svg/User.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-user-parent{

    display:block;
    width:100%;
    height: 100%;

    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/User-group.svg');
        -webkit-mask-image: url('../../../assets/svg/User-group.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-address-book{

    display:block;

    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/address-book.svg');
        -webkit-mask-image: url('../../../assets/svg/address-book.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-kalender{

    display:block;

    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/calendar.svg');
        -webkit-mask-image: url('../../../assets/svg/calendar.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-blog{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Blog.svg');
        -webkit-mask-image: url('../../../assets/svg/Blog.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-cart{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Cart.svg');
        -webkit-mask-image: url('../../../assets/svg/Cart.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-cart-plus{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Cart-plus.svg');
        -webkit-mask-image: url('../../../assets/svg/Cart-plus.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-multi-cart-plus{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Multi-Cart-plus.svg');
        -webkit-mask-image: url('../../../assets/svg/Multi-Cart-plus.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-logo{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/black-flash.svg');
        -webkit-mask-image: url('../../../assets/svg/black-flash.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-burger{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Burger.svg');
        -webkit-mask-image: url('../../../assets/svg/Burger.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-besteck{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Besteck.svg');
        -webkit-mask-image: url('../../../assets/svg/Besteck.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-euro{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Euro.svg');
        -webkit-mask-image: url('../../../assets/svg/Euro.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-project{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Project.svg');
        -webkit-mask-image: url('../../../assets/svg/Project.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-bestandsbuch{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Bestandsbuch.svg');
        -webkit-mask-image: url('../../../assets/svg/Bestandsbuch.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-config{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Gear.svg');
        -webkit-mask-image: url('../../../assets/svg/Gear.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-image{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Image.svg');
        -webkit-mask-image: url('../../../assets/svg/Image.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-okay{
    
	display:block;
	border-radius: (@p_main / 4);
    width: 100%;
    height:100%;

	.standardAnimation(@_time: 150ms);

    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_nanoGreen;
        mask-image: url('../../../assets/svg/check.svg');
        -webkit-mask-image: url('../../../assets/svg/check.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
		i{
            background-color: @c_nanoGreenDark;
        }
    }
}

.pp-link{

    display:block;
    width:100%;
    height:100%;

    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Link.svg');
        -webkit-mask-image: url('../../../assets/svg/Link.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-list{

    display:block;
    width:100%;
    height:100%;

    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/List.svg');
        -webkit-mask-image: url('../../../assets/svg/List.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-unterkategorie{

    display:block;
    width:100%;
    height:100%;

    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/folder-tree.svg');
        -webkit-mask-image: url('../../../assets/svg/folder-tree.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-message{

    display:block;
    width:100%;
    height:100%;

    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Message.svg');
        -webkit-mask-image: url('../../../assets/svg/Message.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-filter{

    display:block;
    width:100%;
    height:100%;

    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Filter.svg');
        -webkit-mask-image: url('../../../assets/svg/Filter.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-archive{

    display:block;
    width:100%;
    height:100%;

    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Archive.svg');
        -webkit-mask-image: url('../../../assets/svg/Archive.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-trash{

    display:block;
	border: 2px solid @c_petrolRed;
	border-radius: (@p_main / 4);
	padding: (@p_main / 2);

	.standardAnimation(@_time: 150ms);

    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_petrolRed;
        mask-image: url('../../../assets/svg/Delete.svg');
        -webkit-mask-image: url('../../../assets/svg/Delete.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
		border: 2px solid @c_petrolRedDark;
        i{
            background-color: @c_petrolRedDark;
        }
    }
}

.pp-industry{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Industry.svg');
        -webkit-mask-image: url('../../../assets/svg/Industry.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-sign-out{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Sign-out.svg');
        -webkit-mask-image: url('../../../assets/svg/Sign-out.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-arrow-right-full{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Arrow-right-3.svg');
        -webkit-mask-image: url('../../../assets/svg/Arrow-right-3.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-arrow-long-right{
    
    display:block;
    
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Arrow-long-right');
        -webkit-mask-image: url('../../../assets/svg/Arrow-long-right.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-arrow-up{

    display:block;
    width: 100%;
    height: 100%;

    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/arrow-up.svg');
        -webkit-mask-image: url('../../../assets/svg/arrow-up.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-arrow-rotate-right{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Arrow-rotate-right.svg');
        -webkit-mask-image: url('../../../assets/svg/Arrow-rotate-right.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-arrow-down{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Chevron-down.svg');
        -webkit-mask-image: url('../../../assets/svg/Chevron-down.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-arrow-right{

    display:block;
    width:100%;
    height:100%;
    
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/chevron-right.svg');
        -webkit-mask-image: url('../../../assets/svg/chevron-right.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-futter{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Futter.svg');
        -webkit-mask-image: url('../../../assets/svg/Futter.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_3rd;
        }
    }
}

.pp-search{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Search.svg');
        -webkit-mask-image: url('../../../assets/svg/Search.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-checkin{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Checkin.svg');
        -webkit-mask-image: url('../../../assets/svg/Checkin.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_3rd;
        }
    }
}

.pp-checkout{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Checkout.svg');
        -webkit-mask-image: url('../../../assets/svg/Checkout.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_3rd;
        }
    }
}

.pp-lock{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Lock.svg');
        -webkit-mask-image: url('../../../assets/svg/Lock.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-add{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Add.svg');
        -webkit-mask-image: url('../../../assets/svg/Add.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-add-small{

    display:block;
    border: 1px solid @c_1st;
    border-radius: @p_main / 4;
    cursor:pointer;

    .standardAnimation(@_time: 150ms);

    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Add.svg');
        -webkit-mask-image: url('../../../assets/svg/Add.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        border-color: @c_3rd;
        i{
            background-color: @c_3rd;
        }
    }
}

.pp-edit{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Stift.svg');
        -webkit-mask-image: url('../../../assets/svg/Stift.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-delete{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Delete.svg');
        -webkit-mask-image: url('../../../assets/svg/Delete.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-circle-minus{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Minus.svg');
        -webkit-mask-image: url('../../../assets/svg/Minus.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-sort{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Sort.svg');
        -webkit-mask-image: url('../../../assets/svg/Sort.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-close{

    display:block;
    width:100%;
    height:100%;

    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Close.svg');
        -webkit-mask-image: url('../../../assets/svg/Close.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-check{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Check.svg');
        -webkit-mask-image: url('../../../assets/svg/Check.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-home{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Home.svg');
        -webkit-mask-image: url('../../../assets/svg/Home.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-folder{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Folder.svg');
        -webkit-mask-image: url('../../../assets/svg/Folder.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-file{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/File.svg');
        -webkit-mask-image: url('../../../assets/svg/File.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-context-dots{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Three_Dots.svg');
        -webkit-mask-image: url('../../../assets/svg/Three_Dots.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-invoice-check{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Invoice-Check.svg');
        -webkit-mask-image: url('../../../assets/svg/Invoice-Check.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-invoice-edit{
    display:block;
    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Invoice-Edit.svg');
        -webkit-mask-image: url('../../../assets/svg/Invoice-Edit.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

.pp-invoice-wait{

    display:block;

    i{
        display:block;
        width:100%;
        height:100%;
        background-color: @c_1st;
        mask-image: url('../../../assets/svg/Invoice-Wait.svg');
        -webkit-mask-image: url('../../../assets/svg/Invoice-Wait.svg');

        .standardAnimation(@_time: 150ms);
    }
    &.active, &:hover{
        i{
            background-color: @c_2nd;
        }
    }
}

