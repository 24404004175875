.iy3FnEIyzfzm {
	font-family: 'Roboto', sans-serif!important;
	font-weight:300!important;
}

.ijdeHuwUF3Qx {
	font-family: 'Roboto', sans-serif!important;
	font-weight: lighter!important;
}

.GHr38RYn79W6 {
	font-family: 'Roboto', sans-serif!important;
	font-weight: 700!important;
}

.QWJzxshdCvaa {
	font-family: 'Cookie', sans-serif!important;
	font-weight: 300!important;
}

.QnjqmxQXPLln{
    font-family: 'Jura', sans-serif!important;
	font-weight: lighter!important;
}

.UDOKY2DhMZBt{
    font-family: 'Jura', sans-serif!important;
	font-weight: 300!important;
}

.VVZSdoHNQcsH{
    font-family: 'Jura', sans-serif!important;
	font-weight: bold!important;
}

.ZNVRuFaynrTN{
    font-family: 'MavenPro', sans-serif!important;
	font-weight: 300!important;
}

.JnAwcvQoJclC{
    font-family: 'MavenPro', sans-serif!important;
	font-weight: bold!important;
}

.PQULCGEHCuic{
    font-family: 'MavenPro', sans-serif!important;
	font-weight: 500!important;
}

.YJUVgZnpWBJC{
    font-family: 'MavenPro', sans-serif!important;
	font-weight: 800!important;
}

.IKqxDZZonmWW{
    font-family: 'Gelasio'!important;
	font-weight: 300!important;
}

.eNSUoCGgopwD{
    font-family: 'Gelasio'!important;
	font-weight: 300!important;
	font-style: italic!important;
}

.kZPxCLGRiGTq{
    font-family: 'Gelasio'!important;
	font-weight: 700!important;
}

.MprIxrbOXVnL{
    font-family: 'Gelasio'!important;
	font-weight: 700!important;
	font-style: italic!important;
}

.bmnWeRHrMpor{
    font-family: 'Gelasio'!important;
	font-weight: 400!important;
}

.esoSEXtmVGRa{
    font-family: 'Gelasio'!important;
	font-weight: 400!important;
	font-style: italic!important;
}

.BYIRCvWNUpkt{
    font-family: 'Gelasio'!important;
	font-weight: 500!important;
}

.fnTDRPaEFrjS{
    font-family: 'Gelasio'!important;
	font-weight: 500!important;
	font-style: italic!important;
}