@import '../../lib/settings.less';
@import '../../classes/classes.less';

.section-row-3-block{

    display: inline-block;
    width: 100%;
    padding-top: (@p_main * 2);
    padding-bottom: (@p_main * 2);

    .wrapper-content{

        .displayFlex(row);
        .justifyContent(center);

        .box{

            display: inline-block;
            width: calc(33% - (@p_main / 2));
            margin: (@p_main / 4);

            h6{
                .f_1stFontBold;

                color: @c_13th;
                font-size: (@f_mainSize * 1.33);
                margin-bottom: @p_main;
            }
            p{
                .f_1stFont;

                color: @c_13th;
                margin: 0;
                line-height: 1.55;
            }
        }

        @media screen and(max-width: @s_2ndMainWrapper){
            
            .displayFlex(column);

            .box{
                width: calc(100% - (@p_main / 2));

                h6, p{
                    text-align: center;
                }
            }
        }
    }
}