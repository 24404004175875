@font-face {
    font-family: 'Roboto-Regular';
    src: url(/assets/fonts/roboto/Roboto-Regular-webfont.ttf) format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url(/assets/fonts/roboto/Roboto-Bold-webfont.ttf) format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Roboto-Thin';
    src: url(/assets/fonts/roboto/Roboto-Thin-webfont.ttf) format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: 'Roboto-Black';
    src: url(/assets/fonts/roboto/Roboto-Black-webfont.ttf) format('truetype');
    font-weight: 800;
}

@font-face {
    font-family: 'Roboto-Light';
    src: url(/assets/fonts/roboto/Roboto-Light-webfont.ttf) format('truetype');
    font-weight: lighter;
}

@font-face {
    font-family: 'Cookie';
    src: url(/assets/fonts/cookie/Cookie-Regular.ttf) format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Gelasio';
    src: url(/assets/fonts/gelasio/Gelasio-Regular.ttf) format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Gelasio';
    src: url(/assets/fonts/gelasio/Gelasio-Italic.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gelasio';
    src: url(/assets/fonts/gelasio/Gelasio-Bold.ttf) format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Gelasio';
    src: url(/assets/fonts/gelasio/Gelasio-BoldItalic.ttf) format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Gelasio';
    src: url(/assets/fonts/gelasio/Gelasio-Medium.ttf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Gelasio';
    src: url(/assets/fonts/gelasio/Gelasio-MediumItalic.ttf) format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Gelasio';
    src: url(/assets/fonts/gelasio/Gelasio-SemiBold.ttf) format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Gelasio';
    src: url(/assets/fonts/gelasio/Gelasio-SemiBoldItalic.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

// --- Fonts
@f_mainSize			: 18px;
@f_mainLineHeight	: 100%;

.f_1stFont {
	font-family: 'Roboto-Regular', sans-serif;
	font-weight:300;
}

.f_1stFontItalic {
	font-family: 'Roboto-Regular', 'sans-serif';
	font-style: italic;
	font-weight:300;
}

.f_1stFontItalic {
	.f_1stFont;
	font-style: italic;
	font-weight: 300;
}

.f_1stFontLight {
	font-family: 'Roboto-Light', sans-serif;
	font-weight: lighter;
}

.f_1stFontLightItalic {
	.f_1stFontLight;
	font-style: italic;
}

.f_1stFontBold {
	font-family: 'Roboto-Bold', sans-serif;
	font-weight: 500;
}

.f_1stFontSemiBold {
	font-family: 'Roboto-Black';
	font-weight: 700;
}

.f_1stFontBoldItalic {
	.f_1stFontBold;
	font-style: italic;
}

.f_1stFontThin{
	font-family: 'Roboto-Thin';
	font-weight: 100;
}

.f_2ndFont {
	font-family: 'Cookie';
	font-weight: 300;
}

.f_2ndFontBold {
	font-family: 'Cookie';
	font-weight: bolder;
}

.f_2ndFontThin{
	.f_1stFontThin;
}


	// -- Colors
	@c_1st		: #058da9;		 // Textfarbe schwarz
	@c_2nd		: #026f85;		
	@c_3rd		: #BB5151;		
	@c_4th		: #82BB51;	
	@c_5th		: #fff;			
	@c_6th		: #000;	        
	@c_7th 		: #DBD8D8;		
	@c_8th		: #9A9A9A;		
	@c_9th      : #717171;       
	@c_10th     : #363636;     
	@c_11th 	: #232323;
	@c_12th 	: #161616;
	@c_13th		: #121212;
	@c_14th		: #111111;
	@c_15th		: #e3e3e3;	
	@c_16th		: #9b2c2c;	
	@c_17th		: #f1f0f0;	
	// @c_17th		: #fafafa;	
	@c_18th		: #25380a;	
	@c_19th		: #455c31;	
	@c_20th		: #f3d3d3;	
	@c_21th		: #f2fce9;
	@c_22th		: #fafafa;	
	@c_23th		: #f67702;	
	@c_24th		: #191919;	
	@c_25th		: #6d3501;	

	@c_petrolRed		: #d20019;
	@c_lpgOrange		: #ef7c00;
	@c_dieselYellow		: #ffcc00;
	@c_nanoGreen		: #78ac28;
	@c_airconBlue		: #009ee3;
	@c_carCareMint		: #13b0ac;
	@c_automatikGrey	: #626261;
	@c_oilGreen			: #4f812d;
	@c_coolerBlue		: #0068b4;

	@c_petrolRedDark	: #9c0013;
	@c_nanoGreenDark	: #496b15;

	// --- Widths & Heights
	@w_page				: 100%;                   // Breite des Seiten Wrappers
	@w_wrapper			: 100%;		              // Breite des Inneren Content Wrappers

	@s_width_fullscreen :100%;
	@s_height_fullscreen:100%;
	@s_width_big        :300px;                     // Logo Höhe Groß
	@s_height_big       :300px;                     // Logo Breite Groß
	@s_width_small      :100px;                     // Logo Höhe Klein
	@s_height_small     :100px;                     // LOgo Breite Klein
	@s_monitor_height	:1080px;					// Monitor Screen Höhe
	@s_monitor_width	:1920px;					// Monitor Screen Breite

	@r_borderRadius		: (@p_main / 2);            // Standard Border-Radius

	@w_header			: 100%;		                // Breite des Headers

	@w_slideshow		: @w_header;	            // Breite der Header Slideshow
	@h_slideshow		: 380px;		            // Höhe der Slideshow

	@w_sidebarFirst		: 24%;			            // Breite Sidebar First
	@w_sidebarSecond	: 20%;			            // Breite Sidebar Second

	@w_pageArea			: 100%;			            // Breite der Gesamten Page Area

	// --- Margins
	@m_main			    : 10px;				        // Standard Margin
	@m_block		    : 10px;			            // Margin für Blöcke
	@m_headlines    	: (@m_main / 1.4);	        // Margin für Headlines (Ausgehend von h1)

	// --- Paddings
	@p_main				: 20px;		                // Standard Padding
	@p_block			: @p_main;

	// --- Responsive Angaben
	@r_status           : false;          //Responsive einschalten

	// --- Höhe Navigation
	@h_navigation: 75px;

	// --- Höhe Footer
	@h_footer: 350px;

	//Textgrößen
	@r_fontSizeText     : 15px;          //Textgröße
	@r_fontSizeH1       : 27px;          //Responsive H1
	@r_fontSizeH2       : 20px;          //Responsive H2
	@r_fontSizeH3       : 17px;          //Responsive H3
	@r_fontSizeH4       : 15px;          //Responsive H4
	@r_fontSizeH5       : 15px;          //Responsive H5
	@r_fontSizeH6       : 15px;          //Responsive H6

// Video Variablen

@track-color: @c_1st;
@thumb-color: @c_5th;

@thumb-radius: 50%;
@thumb-height: 30px;
@thumb-width: 30px;
@thumb-shadow-size: 1px;
@thumb-shadow-blur: 1px;
@thumb-shadow-color: #111;
@thumb-border-width: 1px;
@thumb-border-color: white;

@track-width: 100%;
@track-height: 1px;
@track-shadow-size: 2px;
@track-shadow-blur: 2px;
@track-shadow-color: #222;
@track-border-width: 1px;
@track-border-color: black;

@track-radius: 5px;
@contrast: 5%;

@s_1stMainWrapper	: 1250px;					// HauptWrapper
@s_2ndMainWrapper	: 1060px;					// HauptWrapper
@s_3rdMainWrapper	: 860px;					// HauptWrapper
@s_4thMainWrapper	: 620px;					// HauptWrapper
@s_5thMainWrapper	: 380px;					// HauptWrapper


// Media Querys
@q_1st     		: 1367px;
@q_2nd     		: 1281px;
@q_3rd			: 980px;
@q_4th			: 770px;
@q_5th			: 550px;
@q_top_1st		: 38%;
@q_height_1st	: 65%;

@q_scale_1st	: 65;
