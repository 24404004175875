@import '../../lib/settings.less';
@import '../../classes/classes.less';

.section-impressum{

    display: inline-block;
    width: 100%;
    margin-bottom: @h_navigation;

    p{
        .f_1stFont;

        line-height: 1.75;
        margin: 0;
    }

    h5{
        .f_1stFontBold;

        font-size: (@f_mainSize * 1.25);
        margin-top: @p_main;
    }

    .person{

        display: inline-block;
        width: calc(100% - (@p_main * 2));
        padding: @p_main;
        background-color: @c_15th;
        margin-top: @p_main;
        margin-bottom: @p_main;
    }

    .link{

        display: inline-block;
        width:100%;
        margin-top: (@p_main * 2);

        p{
            font-size: (@f_mainSize * 0.75);
            a{
                font-size: (@f_mainSize * 0.75);
            }
        }
    }
}