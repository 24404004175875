@import '../../lib/settings.less';
@import '../../classes/classes.less';

.section-karte{

    display: inline-block;
    width: 100%;

    .wrapper-content{
        
        display: inline-block;
        padding-top: 0;
        padding-bottom: 0;

        h5{
            .f_1stFontBold;

            font-size: (@f_mainSize * 2);
            color: @c_12th;
            margin-bottom: (@p_main * 2);
        }

        #map{
            
            display: block;
            width: 100%;
            height: 600px;
            margin-top: 0;

            @media screen and(max-width: @s_1stMainWrapper){
                height: 450px;
            }
        }
        @media screen and(max-width: @s_3rdMainWrapper){
            
            // width: calc(100% - (@p_main * 2));
            // margin-left: (@p_main / 2);
            // margin-right: (@p_main / 2);

            #map{
                width: 100%;
            }
        }
    }
}