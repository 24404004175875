@import './settings.less';

@iterationsPercent: 100;
@iterationRow: 11;
@iterationFontColor: 20;
@iterationBackgroundColor: 20;
@iterationFontSize: 120;


// Breite in Prozenten als CSS-Klasse
.mixin-loop (@index) when (@index > 0) {
    .w-@{index} {
      width: @index * 1%!important;
    }
    .mixin-loop(@index - 1);
  }
  .mixin-loop (0) {}
  .mixin-loop(@iterationsPercent);

  // Breite der Rows in Prozenten als CSS-Klasse
.row-loop (@index) when (@index > 0) {
  .row-@{index} {
    width: calc((100% / @iterationRow) * (@index));
  }
  .row-loop(@index - 1);
}
.row-loop (0) {}
.row-loop(@iterationRow);

// Font-Color Loop
.fontcolor-loop (@index) when (@index > 0) {
  .fc-@{index} {
    & when (@index = 1) {
      color: @c_1st!important;
    }
    & when (@index = 2) {
      color: @c_2nd!important;
    }
    & when (@index = 3) {
      color: @c_3rd!important;
    }
    & when (@index = 4) {
      color: @c_4th!important;
    }
    & when (@index = 5) {
      color: @c_5th!important;
    }
    & when (@index = 6) {
      color: @c_6th!important;
    }
    & when (@index = 7) {
      color: @c_7th!important;
    }
    & when (@index = 8) {
      color: @c_8th!important;
    }
    & when (@index = 9) {
      color: @c_9th!important;
    }
    & when (@index = 10) {
      color: @c_10th!important;
    }
    & when (@index = 11) {
      color: @c_11th!important;
    }
    & when (@index = 12) {
      color: @c_12th!important;
    }
    & when (@index = 13) {
      color: @c_13th!important;
    }
    & when (@index = 14) {
      color: @c_14th!important;
    }
    & when (@index = 15) {
      color: @c_15th!important;
    }
    & when (@index = 16) {
      color: @c_16th!important;
    }
    & when (@index = 17) {
      color: @c_17th!important;
    }
    & when (@index = 22) {
      color: @c_22th!important;
    }
    color: @c_1st;
  }

  .hfc-@{index} {
    & when (@index = 1) {
      &:hover{
        color: @c_1st!important;
      }
    }
    & when (@index = 2) {
      &:hover{
        color: @c_2nd!important;
      }
    }
    & when (@index = 3) {
      &:hover{
        color: @c_3rd!important;
      }
    }
    & when (@index = 4) {
      &:hover{
        color: @c_4th!important;
      }
    }
    & when (@index = 5) {
      &:hover{
        color: @c_5th!important;
      }
    }
    & when (@index = 6) {
      &:hover{
        color: @c_6th!important;
      }
    }
    & when (@index = 7) {
      &:hover{
        color: @c_7th!important;
      }
    }
    & when (@index = 8) {
      &:hover{
        color: @c_8th!important;
      }
    }
    & when (@index = 9) {
      &:hover{
        color: @c_9th!important;
      }
    }
    & when (@index = 10) {
      &:hover{
        color: @c_10th!important;
      }
    }
    & when (@index = 11) {
      &:hover{
        color: @c_11th!important;
      }
    }
    & when (@index = 12) {
      &:hover{
        color: @c_12th!important;
      }
    }
    & when (@index = 13) {
      &:hover{
        color: @c_13th!important;
      }
    }
    & when (@index = 14) {
      &:hover{
        color: @c_14th!important;
      }
    }
    & when (@index = 15) {
      &:hover{
        color: @c_15th!important;
      }
    }
    & when (@index = 16) {
      &:hover{
        color: @c_16th!important;
      }
    }
    & when (@index = 17) {
      &:hover{
        color: @c_16th!important;
      }
    }
    & when (@index = 18) {
      &:hover{
        color: @c_18th!important;
      }
    }
    &:hover{
      color: @c_1st;
    }
  }
  .fontcolor-loop(@index - 1);
}
.fontcolor-loop (0) {}
.fontcolor-loop(@iterationFontColor);

// Background-Color Loop
.backgroundcolor-loop (@index) when (@index > 0) {
  .bgc-@{index} {
    & when (@index = 1) {
      background-color: @c_1st!important;
    }
    & when (@index = 2) {
      background-color: @c_2nd!important;
    }
    & when (@index = 3) {
      background-color: @c_3rd!important;
    }
    & when (@index = 4) {
      background-color: @c_4th!important;
    }
    & when (@index = 5) {
      background-color: @c_5th!important;
    }
    & when (@index = 6) {
      background-color: @c_6th!important;
    }
    & when (@index = 7) {
      background-color: @c_7th!important;
    }
    & when (@index = 8) {
      background-color: @c_8th!important;
    }
    & when (@index = 9) {
      background-color: @c_9th!important;
    }
    & when (@index = 10) {
      background-color: @c_10th!important;
    }
    & when (@index = 11) {
      background-color: @c_11th!important;
    }
    & when (@index = 12) {
      background-color: @c_12th!important;
    }
    & when (@index = 13) {
      background-color: @c_13th!important;
    }
    & when (@index = 14) {
      background-color: @c_14th!important;
    }
    & when (@index = 15) {
      background-color: @c_15th!important;
    }
    & when (@index = 16) {
      background-color: @c_16th!important;
    }
    & when (@index = 17) {
      background-color: @c_16th!important;
    }
    background-color: @c_1st;
  }

  .hbgc-@{index} {
    & when (@index = 1) {
      &:hover{
        background-color: @c_1st!important;
      }
    }
    & when (@index = 2) {
      &:hover{
        background-color: @c_2nd!important;
      }
    }
    & when (@index = 3) {
      &:hover{
        background-color: @c_3rd!important;
      }
    }
    & when (@index = 4) {
      &:hover{
        background-color: @c_4th!important;
      }
    }
    & when (@index = 5) {
      &:hover{
        background-color: @c_5th!important;
      }
    }
    & when (@index = 6) {
      &:hover{
        background-color: @c_6th!important;
      }
    }
    & when (@index = 7) {
      &:hover{
        background-color: @c_7th!important;
      }
    }
    & when (@index = 8) {
      &:hover{
        background-color: @c_8th!important;
      }
    }
    & when (@index = 9) {
      &:hover{
        background-color: @c_9th!important;
      }
    }
    & when (@index = 10) {
      &:hover{
        background-color: @c_10th!important;
      }
    }
    & when (@index = 11) {
      &:hover{
        background-color: @c_11th!important;
      }
    }
    & when (@index = 12) {
      &:hover{
        background-color: @c_12th!important;
      }
    }
    & when (@index = 13) {
      &:hover{
        background-color: @c_13th!important;
      }
    }
    & when (@index = 14) {
      &:hover{
        background-color: @c_14th!important;
      }
    }
    & when (@index = 15) {
      &:hover{
        background-color: @c_15th!important;
      }
    }
    & when (@index = 16) {
      &:hover{
        background-color: @c_16th!important;
      }
    }
    & when (@index = 17) {
      &:hover{
        background-color: @c_16th!important;
      }
    }
    &:hover{
      background-color: @c_1st;
    }
  }
  .backgroundcolor-loop(@index - 1);
}
.backgroundcolor-loop (0) {}
.backgroundcolor-loop(@iterationBackgroundColor);

// Font-Size Loop
.fontSize-loop (@index) when (@index > 0) {
  .fs-@{index} {
    font-size: ~"@{index}px"!important;
  }
  .fontSize-loop(@index - 1);
}
.fontSize-loop (0) {}
.fontSize-loop(@iterationFontSize);


